import React from 'react';
import { useLocation, Link as RouteLink } from 'react-router-dom';

import { Typography, Button } from '@material-ui/core';

import pathes from 'core/configs/pathesConfig';

import { useSelector } from 'react-redux';
import getHeaderInfo from './config';
import Logo from './Logo';

const Header = () => {
  const redirectUrl = useSelector((state) => state.appVariables?.data?.redirectUrl);

  const getFeature = () => {
    let feature;

    Object.keys(pathes).forEach((key) => {
      if (useLocation().pathname.includes(pathes[key])) {
        feature = key;
      }
    });

    return feature;
  };
  const feature = getFeature();

  return (
    <header className="header">
      <div className="header__logo-wrap">
        <Logo />
      </div>
      {getHeaderInfo[feature] ? (
        <div className="header__sign-in-wrap">
          <Typography variant="body2" component="span" className="header__sign-in-text">
            {getHeaderInfo[feature].text}
          </Typography>
          <Button
            component={RouteLink}
            variant="contained"
            onClick={() => {
              window.location.href = redirectUrl;
            }}
            color="secondary"
            className="header__sign-in-link"
            // to={getHeaderInfo[feature].routePath}
            data-testid="route-button"
          >
            {getHeaderInfo[feature].buttonText}
          </Button>
        </div>
      ) : ''}
    </header>
  );
};

export default Header;
