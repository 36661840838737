import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';

// import pathes from 'core/configs/pathesConfig';
import successImage from 'assets/images/success.svg';
import { useSelector } from 'react-redux';

const SuccessfulSignup = () => {
  const redirectUrl = useSelector((state) => state?.appVariables?.data?.redirectUrl);

  return (

    <div className="successful-signup__wrap">
      <img src={successImage} width="119" height="112" alt="Success" />
      <Typography variant="h2" component="h2" className="successful-signup__title">Congratulations, you have registered successfully!</Typography>
      <Typography variant="body1" component="p" className="successful-signup__text">
        To continue go to Sign In page
      </Typography>
      <Button
        component={RouteLink}
        variant="contained"
        color="primary"
        className="successful-signup__btn"
        data-testid="successful-signup__btn"
        // to={pathes.signin}
        onClick={() => {
          window.location.href = redirectUrl;
        }}
      >
        Sign in
      </Button>
    </div>
  );
};

export default SuccessfulSignup;
